/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Actionable, Button, Card, Divider, Icon, Stack } from "@bookingcom/bui-react";

import { trackCustomGoal, trackExperiment, trackExperimentStage } from "utils/et";
import VIAlert from "../../VIAlert";
import BaseFareAttributes from "./BaseFareAttributes";
import BaseFareName from "./BaseFareName";
import FlightCardCarriers from "./FlightCardCarriers";
import FlightCardLuggage from "./FlightCardLuggage";
import FlightCardPrice from "./FlightCardPrice";
import FlightCardSegment from "./FlightCardSegment";
import FlightCardSegmentMobile from "./FlightCardSegment.mobile";
import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";
import { useStore } from "../../../../store";
import { FlightCardBoundProps } from "./FlightCardBound.desktop";
import { ArrowNavDownIcon, ArrowNavUpIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { AnimatePresence, motion } from "framer-motion";
import useIsInViewport from "../../../../hooks/useIsInViewport";
import FareSelector from "components/elements/FareSelector/components/FareSelector";
import useFetchBrandedFares from "components/elements/FareSelector/hooks/useFetchBrandedFares";
import FlightCardFullLuggageData from "./FlightCardFullLuggageData";
import { isFeatureRunningClientSide } from "utils/features";
import { FlightCardHighlight, FlightCardHighlightStyle } from "./FlightCardHighlight";
import { FlightCardBadges } from "./FlightCardBadges";
import { FlightCardBadge } from "./FlightCardBadge";
import Frame from "components/elements/Frame";
import styles from "./FlightCardBound.module.css";
import { getCampaignBadgesConfig, getCampaignBestOfferHighlightConfig } from "utils/flightCampaignConfig";
import { RebookCostBreakdown } from "components/elements/RebookFlow/RebookCostBreakdown/RebookCostBreakdown";
import { FlightBadgeVariant } from "@flights/types";
import { trackFlightCardsVisible as trackFlightCardsVisibleForBfPriceDisplay } from "utils/experiments/apex/flights_apex_web_bf_price_display";
import debounce from "lodash/debounce";
import { mcn } from "../../../../utils/mergeClassnames";
import trackCtaActionReinforceMdot, {
  trackGoalCtaActionReinforceMdot
} from "utils/experiments/funnel/flights_web_cta_action_reinforce_sr_mdot_v2";
import flights_apex_web_ancillaries_micro_conversion_aa from "utils/experiments/apex/flights_apex_web_ancillaries_micro_conversion_aa";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import { getMarketingCarriers } from "utils/carriers";
import { trackRebookPriceReviewM3 } from "utils/experiments/post-booking/flights_web_pb_rebook_price_review_m3";
import useProfiler from "hooks/useProfiler";

type Props = FlightCardBoundProps;

const FlightCardBound: FC<Props> = (props) => {
  const {
    index,
    flight,
    onClick,
    pricePerPerson,
    rebookProps,
    /* start - flights_web_inbound_outbound_sr */
    open,
    inboundFlight,
    inboundFlightsContent,
    inboundFlightsCount,
    forceButtonFocusOnMount = false
    /* end - flights_web_inbound_outbound_sr */
  } = props;
  const [breakdownOpen, setBreakdownOpen] = useState(false);
  const { searchCriteria, searchResults, brandedFares } = useStore();
  const { adults, children } = searchCriteria;
  const i18n = useI18n() as I18nChildContext;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showFareSelector, toggleShowFareSelector] = useState(false);
  const flightCardBoundRef = useRef<HTMLButtonElement>(null);
  const isVisible = useIsInViewport(flightCardBoundRef.current, undefined, { threshold: 0.7 });
  const isInViewport = useIsInViewport(flightCardBoundRef.current);

  const shouldPinOfferOnSr =
    flight.highlightInfo?.highlight &&
    ((Boolean(trackExperiment("flights_web_cat_pin_flight_mdot")) && isOfMetaOrigin()) ||
      isFeatureRunningClientSide("FLIGHTS_WEB_PIN_OFFER_ON_SR_FROM_REDIRECT") ||
      flight.highlightInfo.reason === "CROSS_SELL_OFFER");

  const { bestOfferHighlightStyle, bestOfferHighlightText } = getCampaignBestOfferHighlightConfig(
    flight.campaignDisplay?.bestOfferHighlight
  );
  const shouldShowBestOfferHightlight = !!(bestOfferHighlightStyle && bestOfferHighlightText);

  const campaignBadges = getCampaignBadgesConfig(flight.campaignDisplay?.badges);

  const carriers = getMarketingCarriers(flight.segments);

  const brandedFaresAvailable = (brandedFares.fareOffersWithDetails[flight.token]?.brandedFareOffers?.length || 0) > 0;
  const hideBrandedFaresBeforeAvailable = isFeatureRunningClientSide("FLIGHTS_ONLY_SHOW_BF_ON_SR_CTA_WHEN_AVAILABLE");

  useFetchBrandedFares(
    flight.token,
    isInViewport,
    flight.requestableBrandedFares ?? false,
    true,
    !!flight.brandedFareInfo?.fareName,
    carriers
  );

  useEffect(() => {
    if (forceButtonFocusOnMount && buttonRef.current) {
      buttonRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const travellersCount = adults + children.length;
  const isOutboundFlightCard = typeof open !== "undefined";
  const hasMoreThanOneInboundFlight = (flight.outboundFlightInfo?.inboundFlightsCount || 0) > 1;

  const toggleButtonLabel = useMemo(() => {
    if (open) {
      return inboundFlightsCount === 1
        ? t("flights_sr_rt_return_button_hide_single")
        : t("flights_sr_rt_return_button_hide");
    }

    return inboundFlightsCount === 1
      ? t("flights_sr_rt_return_button_view_single")
      : t("flights_sr_rt_return_button_view");
  }, [inboundFlightsCount, open]);

  const trackFlightCardsVisibleForBfPriceDisplayDebounced = useMemo(
    () => debounce(trackFlightCardsVisibleForBfPriceDisplay, 2000),
    []
  );

  useEffect(() => {
    trackFlightCardsVisibleForBfPriceDisplayDebounced(isVisible, index, searchResults.flights.length);
  }, [isVisible, index, searchResults.flights.length, trackFlightCardsVisibleForBfPriceDisplayDebounced]);

  const { segments } = flight;
  const areAllSegmentsDirect = useMemo(() => segments.every(({ legs }) => legs.length === 1), [segments]);
  const areAllSegmentsNonDirect = useMemo(() => segments.every(({ legs }) => legs.length > 1), [segments]);
  const areSegmentsMixed = useMemo(
    () => segments.some(({ legs }) => legs.length === 1) && segments.some(({ legs }) => legs.length > 1),
    [segments]
  );

  const handleOnClick = useCallback(() => {
    !breakdownOpen && onClick(flight);

    areAllSegmentsDirect && trackCustomGoal("flights_web_sr_card_itinerary_redesign_mdot", 1);
    areAllSegmentsNonDirect && trackCustomGoal("flights_web_sr_card_itinerary_redesign_mdot", 2);
    areSegmentsMixed && trackCustomGoal("flights_web_sr_card_itinerary_redesign_mdot", 3);
  }, [breakdownOpen, flight, onClick, areAllSegmentsDirect, areAllSegmentsNonDirect, areSegmentsMixed]);

  const hasFlexibleTicketBadge = flight.ancillaries?.flexibleTicket && !flight.requestableBrandedFares;
  const showFlightCardBadges = flight.badges?.length || campaignBadges.length > 0 || hasFlexibleTicketBadge;
  const isActionReinforce = !!trackCtaActionReinforceMdot.variant();

  // flights_web_mdot_sr_card_single_cta -- start
  const profiler = useProfiler();
  const onClickExpand = useCallback(() => {
    trackCustomGoal("flights_web_mdot_sr_card_single_cta", 1);
  }, []);

  useEffect(() => {
    trackExperimentStage("flights_web_mdot_sr_card_single_cta", 1);
    if (profiler.isDirect()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 2);
    if (profiler.isMeta()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 3);
    if (profiler.isSolo()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 4);
    if (!profiler.isSolo()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 5);
  }, [flight.requestableBrandedFares, profiler]);
  // flights_web_mdot_sr_card_single_cta -- end

  return (
    <Card
      fill
      className={mcn(styles.card, open && styles.cardShadow)}
      attributes={{ ref: flightCardBoundRef }}
      variant={shouldPinOfferOnSr ? "elevated" : "neutral"}
    >
      <Actionable ref={buttonRef} onClick={handleOnClick} className={mcn(styles.button, styles.outboundButtonCard)}>
        {shouldPinOfferOnSr ? (
          <FlightCardHighlight
            style={FlightCardHighlightStyle.PRIMARY}
            text={flight.highlightInfo?.message || i18n.trans(t("flights_meta_sr_pinned_flight_label"))}
          />
        ) : shouldShowBestOfferHightlight ? (
          <FlightCardHighlight style={bestOfferHighlightStyle} text={bestOfferHighlightText} textEmphasized={true} />
        ) : null}

        <Frame
          pt={4}
          pr={4}
          pb={trackExperiment("flights_web_sr_card_itinerary_redesign_mdot") ? 2 : 4}
          pl={4}
          direction="column"
          justifyContent="space-between"
        >
          {showFlightCardBadges && (
            <FlightCardBadges className={styles.badges}>
              {campaignBadges.map((badge, idx) => (
                <FlightCardBadge key={`keyCampaignBadge-${idx}`} alternative={false} isInteractive={false} {...badge} />
              ))}

              {flight?.badges?.map(({ text, variant, type }, idx) => (
                <FlightCardBadge key={text + idx} text={text} style={variant} alternative type={type} />
              ))}

              {hasFlexibleTicketBadge && (
                <FlightCardBadge
                  style={FlightBadgeVariant.CONSTRUCTIVE}
                  alternative={true}
                  text={i18n.trans(t("flights_apex_sr_flex_badge"))}
                />
              )}
            </FlightCardBadges>
          )}

          <BaseFareAttributes flight={flight} />
          <Frame
            attributes={{
              style: {
                width: "100%"
              }
            }}
            justifyContent="space-around"
          >
            {flight.segments.map((segment, segmentIndex) => {
              return (
                <Frame
                  key={segmentIndex}
                  className={mcn(
                    styles.segment,
                    trackExperiment("flights_web_sr_card_itinerary_redesign_mdot") ? styles.segmentCompact : undefined
                  )}
                >
                  {trackExperiment("flights_web_sr_card_itinerary_redesign_mdot") ? (
                    <FlightCardSegmentMobile segment={segment} idx={segmentIndex} />
                  ) : (
                    <FlightCardSegment segment={segment} idx={segmentIndex} />
                  )}

                  {!!trackExperiment("flights_web_q3_blackout") && (
                    <Frame>
                      <VIAlert
                        segments={segment}
                        align="center"
                        variant={trackExperiment("flights_web_sr_card_itinerary_redesign_mdot") ? "small_2" : "small_1"}
                        isSearchResults
                      />
                    </Frame>
                  )}
                  <Frame mt={trackExperiment("flights_web_sr_card_itinerary_redesign_mdot") ? 0 : 3}>
                    <FlightCardCarriers segment={segment} idx={segmentIndex} />
                  </Frame>
                </Frame>
              );
            })}
          </Frame>
          {!trackExperiment("flights_web_sr_full_luggage_data") && (isOutboundFlightCard || inboundFlight) && (
            <Frame>
              <FlightCardLuggage
                segments={flight.segments}
                horizontal={true}
                brandedFareInfo={flight.brandedFareInfo}
              />
            </Frame>
          )}
          <Divider
            className={mcn(
              styles.divider,
              trackExperiment("flights_web_sr_card_itinerary_redesign_mdot") ? styles.dividerCompact : undefined
            )}
          />
          {!!trackExperiment("flights_web_sr_full_luggage_data") && (
            <>
              <FlightCardFullLuggageData
                includedLuggagePerSegment={flight.includedProducts?.segments}
                segments={flight.segments}
                ancillaries={flight.ancillaries}
                brandedFareInfo={flight.brandedFareInfo}
              />

              <Divider className={styles.divider} />
            </>
          )}
          <Frame direction="row" justifyContent="space-between" alignItems="center" className={styles.priceBlock}>
            {rebookProps && trackRebookPriceReviewM3.variant() === 1 ? (
              <Frame direction="row" alignItems="center" justifyContent="flex-start" className={styles.luggage}>
                <FlightCardLuggage segments={rebookProps.tripRebookOption.segments} />
              </Frame>
            ) : !trackExperiment("flights_web_sr_full_luggage_data") && !isOutboundFlightCard && !inboundFlight ? (
              <Frame direction="row" alignItems="center" justifyContent="flex-start" className={styles.luggage}>
                <FlightCardLuggage segments={flight.segments} brandedFareInfo={flight.brandedFareInfo} />
              </Frame>
            ) : null}

            <Frame className={styles.price}>
              <BaseFareName flight={flight} />
              {rebookProps ? (
                <RebookCostBreakdown
                  {...rebookProps}
                  breakdownOpen={breakdownOpen}
                  setBreakdownOpen={setBreakdownOpen}
                />
              ) : (
                <FlightCardPrice
                  flight={flight}
                  breakdownOpen={breakdownOpen}
                  setBreakdownOpen={setBreakdownOpen}
                  showPricePerPerson={pricePerPerson && adults + children.length > 1}
                  showAvailableSeats={true}
                  totalPriceLabel={
                    isOutboundFlightCard
                      ? i18n.trans(
                          travellersCount > 1 ? t("flights_sr_rt_price_per") : t("flights_sr_price_round_trip")
                        )
                      : undefined
                  }
                  priceFrom={isOutboundFlightCard && hasMoreThanOneInboundFlight}
                  hidePriceBreakDown={isOutboundFlightCard}
                  popoverCloseAriaLabel={i18n.trans(t("flights_a11y_price_breakdown_close_sr"))}
                />
              )}

              {isOutboundFlightCard && (
                <Button
                  text={i18n.trans(toggleButtonLabel)}
                  variant="secondary"
                  iconPosition="end"
                  icon={
                    <Icon
                      svg={ArrowNavDownIcon}
                      className={open ? styles.arrow : undefined}
                      attributes={{ "aria-hidden": true }}
                    />
                  }
                  className={styles.fakeButton}
                  attributes={{ "aria-expanded": open }}
                />
              )}

              {inboundFlight && (
                <Button
                  text={i18n.trans(t("flights_sr_rt_button_details"))}
                  variant="primary"
                  className={styles.fakeButton}
                />
              )}
            </Frame>
          </Frame>
        </Frame>
      </Actionable>

      <Frame className={styles.buttons}>
        <Stack gap={3}>
          {flight.requestableBrandedFares && !!trackExperiment("flights_web_mdot_sr_card_single_cta") ? (
            <Button
              attributes={{ "aria-expanded": showFareSelector, "aria-controls": "flights-fare-selector" }}
              text={i18n.trans(t("flights_sr_bf_cta_ticket_options"))}
              onClick={(e) => {
                e.stopPropagation();
                onClickExpand();
                toggleShowFareSelector(!showFareSelector);
                flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
                trackGoalCtaActionReinforceMdot(!showFareSelector);
              }}
              variant={isActionReinforce ? "primary" : "secondary"}
              iconPosition="end"
              icon={
                <Icon
                  svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon}
                  className={open ? styles.arrow : undefined}
                  attributes={{ "aria-hidden": true }}
                />
              }
              className={`${styles.button} ${
                !brandedFaresAvailable && hideBrandedFaresBeforeAvailable ? styles.hidden : ""
              }`}
            />
          ) : (
            <>
              <Button
                ref={buttonRef}
                attributes={{
                  "aria-label": `${i18n.trans(t("flights_apex_bf_sr_cta1_view_details"))} ${
                    hasFlexibleTicketBadge ? i18n.trans(t("flights_apex_sr_flex_badge")) : ""
                  }`
                }}
                text={i18n.trans(t("flights_apex_bf_sr_cta1_view_details"))}
                onClick={() => {
                  onClick(flight);
                }}
                variant={isActionReinforce ? "primary" : "secondary"}
                className={styles.button}
              />
              {flight.requestableBrandedFares && (
                <Button
                  attributes={{ "aria-expanded": showFareSelector, "aria-controls": "flights-fare-selector" }}
                  text={
                    showFareSelector
                      ? i18n.trans(t("flights_apex_sr_flex_cta_hide"))
                      : i18n.trans(t("flights_apex_sr_flex_cta_explore"))
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickExpand();
                    toggleShowFareSelector(!showFareSelector);
                    flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
                    trackGoalCtaActionReinforceMdot(!showFareSelector);
                  }}
                  variant="tertiary"
                  iconPosition="end"
                  icon={
                    <Icon
                      svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon}
                      className={open ? styles.arrow : undefined}
                      attributes={{ "aria-hidden": true }}
                    />
                  }
                  className={`${styles.button} ${
                    !brandedFaresAvailable && hideBrandedFaresBeforeAvailable ? styles.hidden : ""
                  }`}
                />
              )}
            </>
          )}
        </Stack>
      </Frame>

      {showFareSelector && <FareSelector flight={flight} />}

      {isOutboundFlightCard && (
        <AnimatePresence initial={false}>
          {open && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Frame mb={4} pr={4}>
                {inboundFlightsContent}
              </Frame>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Card>
  );
};

const FlightCard = React.memo(FlightCardBound);

export default FlightCard;
