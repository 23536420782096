import React, { FC, ReactNode, useRef, useEffect } from "react";
import { Text, Title } from "@bookingcom/bui-react";
import useUserAgent from "../../../hooks/useUserAgent";
import Frame from "../Frame";
import styles from "./FlightDetailsInfoSection.module.css";
import { mcn } from "utils/mergeClassnames";
import useIsInViewport from "hooks/useIsInViewport";
import flights_apex_web_insurance_on_flight_details from "utils/experiments/apex/flights_apex_web_insurance_on_flight_details";

const FlightDetailsInfoSection: FC<{
  content: ReactNode;
  header?: ReactNode;
  subheader?: ReactNode;
  hideBorders?: boolean;
  showsExtrasTitle?: boolean;
}> = ({ content, header, subheader, hideBorders, showsExtrasTitle }) => {
  const { isMobile } = useUserAgent();
  const extrasTitleRef = useRef<HTMLElement | null>(null);

  const extrasTitleInViewport = useIsInViewport(extrasTitleRef.current);

  useEffect(() => {
    if (extrasTitleInViewport && showsExtrasTitle)
      flights_apex_web_insurance_on_flight_details.stages.extras_title_visible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Frame
      direction={isMobile ? "column" : "row"}
      pt={4}
      pb={4}
      className={hideBorders ? mcn(styles.root, styles.rootNoBorder) : styles.root}
    >
      {header && (
        <Title
          className={styles.headingWrapper}
          title={header}
          subtitle={
            subheader && (
              <Text className={styles.subheader} variant="body_2">
                {subheader}
              </Text>
            )
          }
          variant="strong_1"
          titleTagName="h3"
          attributes={{
            ref: extrasTitleRef,
            "data-testid": "flight_details_info_section_title"
          }}
        />
      )}
      <div className={styles.contentWrapper}>{content}</div>
    </Frame>
  );
};

export default FlightDetailsInfoSection;
