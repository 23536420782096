import {
  UIAtolProtectedStatus,
  UICampaignBanner,
  UICarrierBaggagePolicies,
  UIClientFetchError,
  UIFetchStatus,
  UIFlightData,
  UISearchCabinClassExtension,
  UISearchResultsAggregation,
  UISearchResultsBanner,
  UISearchResultsFlightDeal,
  UISearchResultsSearchCriteria,
  UISubsidizedFaresSummary
} from "@flights/types";

export type State = {
  [x: string]: any;
  campaignBanner?: UICampaignBanner;
  fetchStatus: UIFetchStatus;
  flights: UIFlightData[];
  atolProtectedStatus?: UIAtolProtectedStatus;
  availableFilters: UISearchResultsAggregation;
  subsidizedFaresSummary: UISubsidizedFaresSummary;
  cabinClassExtension?: UISearchCabinClassExtension;
  baggagePolicies?: UICarrierBaggagePolicies[];
  error?: UIClientFetchError;
  flightDeals?: UISearchResultsFlightDeal[];
  directFlightsOnlyFilterIgnored?: boolean;
  searchCriteria?: UISearchResultsSearchCriteria;
  searchId?: string;
  /* start - flights_web_inbound_outbound_sr */
  selectedInboundFlight?: UIFlightData;
  /* end - flights_web_inbound_outbound_sr */
  banners?: UISearchResultsBanner[];
};

export const getInitialState = (): State => ({
  fetchStatus: "initial",
  flights: [],
  availableFilters: {},
  subsidizedFaresSummary: [],
  error: undefined,
  campaignBanner: undefined,
  searchCriteria: undefined,
  searchId: undefined,
  banners: undefined
});
