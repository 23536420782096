import React, { useRef } from "react";
import { UIAvailableExtraProducts, UIBrandedFareOrFlexTicket, UIFetchStatus, UIFlightData } from "@flights/types";
import { lowerCase, startCase } from "lodash";
import { Button, Card, Popover, SkeletonLoader, Stack, Text, useTheme } from "@bookingcom/bui-react";
import FareFeaturesIcons from "./FareFeaturesIcons";
import FareCardPrice from "./FareCardPrice";
import FareFeatures from "components/elements/CheckoutFareInner/components/FareFeatures";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useGoToFlightDetails from "components/elements/FareSelector/hooks/useGoToFlightDetails";
import styles from "./FareCard.desktop.module.css";
import { areSellableFeaturesRequiredByAirline } from "utils/brandedFares";

type FareCardProps = {
  fareOffer: UIBrandedFareOrFlexTicket;
  baseOffer: UIFlightData;
  loadingStatus?: UIFetchStatus;
  index?: number;
  numberOfOffers?: number;
  ancillaries?: UIAvailableExtraProducts;
};

const FareCardDesktop = ({ baseOffer, fareOffer, loadingStatus, index, numberOfOffers }: FareCardProps) => {
  const { brandedFareInfo, includedProducts, isFlexTicket, ancillaries } = fareOffer;
  const i18n = useI18n();
  const goToFlightDetails = useGoToFlightDetails();
  const theme = useTheme();
  const sellableFeaturesRequiredByAirline = areSellableFeaturesRequiredByAirline(baseOffer.segments);
  const popoverRef = useRef(document.body);

  const renderedFeatures = (
    <FareFeatures
      brandedFareInfo={brandedFareInfo}
      includedProducts={includedProducts}
      ancillaries={ancillaries}
      sellableFeaturesRequiredByAirline={sellableFeaturesRequiredByAirline}
      showHeader={true}
      isPreCheckAndPay={true}
    />
  );

  return (
    <Popover triggerType="hover" containerRef={popoverRef}>
      <Popover.Trigger className={styles.popoverTrigger}>
        {(attributes) => (
          <Card className={styles.card}>
            <div>
              <Stack
                attributes={{
                  ...attributes,
                  tabIndex: 0
                }}
                justifyContent="space-between"
              >
                <Text className={styles.title} variant="strong_1" attributes={{ "data-fare-card-row": "title" }}>
                  {loadingStatus === "loading" ? (
                    <Stack gap={6} direction="row">
                      <Stack.Item grow>
                        <SkeletonLoader />
                      </Stack.Item>
                      <SkeletonLoader width={theme.units.spacing_12x} />
                    </Stack>
                  ) : (
                    startCase(lowerCase(brandedFareInfo?.fareName))
                  )}
                </Text>
                <div>
                  <div className={styles.fareFeaturesIcons} data-fare-card-row="icons">
                    <FareFeaturesIcons
                      includedProducts={includedProducts}
                      brandedFareInfo={brandedFareInfo}
                      limit={7}
                    />
                  </div>
                  <FareCardPrice fareOffer={fareOffer} baseOffer={baseOffer} isFlexTicket={isFlexTicket} />
                  {renderedFeatures && (
                    <div id="features-aria" className={styles.ariaInvisible}>
                      {renderedFeatures}
                    </div>
                  )}
                </div>
              </Stack>
              <Button
                onClick={() => {
                  goToFlightDetails(fareOffer.token, baseOffer, isFlexTicket);
                }}
                className={styles.button}
                variant="secondary"
                attributes={{
                  ...(index !== undefined && {
                    "aria-label": `${index + 1} of ${numberOfOffers}`
                  })
                }}
              >
                {i18n.trans(t("flights_apex_bf_sr_bf_card_cta"))}
              </Button>
            </div>
          </Card>
        )}
      </Popover.Trigger>
      <Popover.Content
        attributes={{
          style: {
            width: 240,
            whiteSpace: "pre-wrap",
            overflow: "hidden"
          }
        }}
      >
        {renderedFeatures}
      </Popover.Content>
    </Popover>
  );
};
export default FareCardDesktop;
